<template>
  <v-row style="padding: 15px" no-gutters>
    <!-- <v-col
      cols="12"
      style="padding: 0 10px;margin-top:30px;"
      class="d-flex justify-center"
    >
      <v-card class="mx-auto;" style="width:200px; margin: 0 10px;">
        <v-card-text style="text-align:center;">
          <div style="font-weight:bold;">Meninggalkan Pekerjaan</div>
          <div class="d-flex justify-center">
            <p
              class="text-h4 text--primary"
              style="margin:0;font-weight:bolder;"
            >
              0
            </p>
            <p style="margin:0; margin-top:auto;">
              Hari
            </p>
          </div>
        </v-card-text>
      </v-card>
      <v-card class="mx-auto;" style="width:200px; margin: 0 10px;">
        <v-card-text style="text-align:center;">
          <div style="font-weight:bold;">Dinas Luar</div>
          <div class="d-flex justify-center">
            <p
              class="text-h4 text--primary"
              style="margin:0;font-weight:bolder;"
            >
              0
            </p>
            <p style="margin:0; margin-top:auto;">
              Hari
            </p>
          </div>
        </v-card-text>
      </v-card>
      <v-card class="mx-auto;" style="width:200px; margin: 0 10px;">
        <v-card-text style="text-align:center;">
          <div style="font-weight:bold;">Terlambat</div>
          <div class="d-flex justify-center">
            <p
              class="text-h4 text--primary"
              style="margin:0;font-weight:bolder;"
            >
              0
            </p>
            <p style="margin:0; margin-top:auto;">
              Hari
            </p>
          </div>
        </v-card-text>
      </v-card>
      <v-card class="mx-auto;" style="width:200px; margin: 0 10px;">
        <v-card-text style="text-align:center;">
          <div style="font-weight:bold;">Sakit</div>
          <div class="d-flex justify-center">
            <p
              class="text-h4 text--primary"
              style="margin:0;font-weight:bolder;"
            >
              0
            </p>
            <p style="margin:0; margin-top:auto;">
              Hari
            </p>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" style="padding: 0 10px; text-align:right;">
      <br />
    </v-col> -->
    <v-col cols="12" style="padding: 0 10px; text-align:left; margin:auto;">
      <!-- <br />
      <br /> -->
      <div class="d-flex justify-content-between align-center">
        <!-- <v-btn
          type="button"
          rounded
          elevation="0"
          color="success"
          class="white--text font-weight-bold"
          style="font-size:12px;"
          @click="exportData"
        >
          Export Report
          <v-icon style="margin-left:5px;" small>mdi-download</v-icon>
        </v-btn> -->
        <v-toolbar-title class="overline">
          <p
            style="font-size: 16px; margin: 0; font-weight: bold; color:indigo;"
          >
            Pengajuan Izin
          </p>
        </v-toolbar-title>
        <router-link to="/hr/permission/submission">
          <v-btn
            type="button"
            rounded
            elevation="0"
            color="primary"
            class="white--text font-weight-bold"
            style="font-size:12px;"
          >
            Formulir Pengajuan Izin
          </v-btn>
        </router-link>
      </div>
    </v-col>
    <v-col cols="12" style="padding: 0 10px">
      <div flat class="d-flex justify-content-between align-center">
        <div class="d-flex">
          <div style="width: 220px;">
            <v-text-field
              v-model="paramAPI.date"
              dense
              outlined
              type="date"
              name="date"
              step="1"
              style="
                        position: relative;
                        top: 15px;
                        font-size: 12px;
                      "
            />
          </div>
          <!-- <div style="width: 180px; margin-left:20px;">
            <v-select
              label="Jenis Izin"
              v-model="paramAPI.permission_type"
              :items="[
                {
                  id: 1,
                  name: 'Sakit'
                },
                {
                  id: 2,
                  name: 'Terlambat'
                },
                {
                  id: 3,
                  name: 'Meninggalkan Pekerjaan'
                },
                {
                  id: 4,
                  name: 'Ganti Hari Kerja'
                }
              ]"
              style="
                        position: relative;
                        top: 15px;
                        font-size: 12px;
                      "
              item-text="name"
              item-value="id"
              return-id
              dense
              outlined
            ></v-select>
          </div>
          <div style="width: 180px; margin-left:20px;">
            <v-select
              label="Status"
              v-model="paramAPI.status"
              :items="[
                {
                  id: 1,
                  name: 'Pending'
                },
                {
                  id: 2,
                  name: 'Approved'
                },
                {
                  id: 3,
                  name: 'Reject'
                }
              ]"
              style="
                        position: relative;
                        top: 15px;
                        font-size: 12px;
                      "
              item-text="name"
              item-value="id"
              return-id
              dense
              outlined
            ></v-select>
          </div> -->
        </div>
        <div style="margin-left:20px;">
          <v-text-field
            v-model="paramAPI.keyword"
            label="Cari disini"
            type="search"
            outlined
            dense
            append-icon="mdi-magnify"
            style="position: relative; top: 15px;"
            @keyup.enter="searchEnter"
            :disabled="loading"
          ></v-text-field>
        </div>
      </div>

      <v-data-table
        mobile-breakpoint="0"
        fixed-header
        min-height="20vh"
        :headers="headers"
        style="cursor: pointer;"
        :items="response"
        :loading="loading"
        @click:row="rowClick"
        item-key="id"
        :options.sync="options"
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
        }"
        :server-items-length="totalData"
        @update:page="updatePage"
        @update:items-per-page="updateItemPerPage"
      >
        <template v-slot:[`item.employee`]="{ item }">
          <div
            style="font-size:12px; padding: 0; display: flex; flex-direction: row"
          >
            {{ item.employee.name }}
          </div>
        </template>
        <template v-slot:[`item.company`]="{ item }">
          <div
            v-if="item.company !== null"
            style="font-size:12px; padding: 0; display: flex; flex-direction: row"
          >
            {{ item.company.name }}
          </div>
        </template>
        <template v-slot:[`item.department`]="{ item }">
          <div
            style="font-size:12px; padding: 0; display: flex; flex-direction: row"
          >
            {{ item.department.name }}
          </div>
        </template>
        <template v-slot:[`item.permission_type`]="{ item }">
          <div
            style="font-size:12px; padding: 0; display: flex; flex-direction: row"
          >
            {{ item.permission_type.name }}
          </div>
        </template>
        <template v-slot:[`item.start_date`]="{ item }">
          <div
            style="font-size:12px; padding: 0; display: flex; flex-direction: row"
          >
            {{ convertDate(item.start_date) }}
          </div>
        </template>
        <template v-slot:[`item.end_date`]="{ item }">
          <div
            style="font-size:12px; padding: 0; display: flex; flex-direction: row"
          >
            {{ convertDate(item.end_date) }}
          </div>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          <div
            style="font-size:12px; padding: 0; display: flex; flex-direction: row"
          >
            {{ convertDate(item.created_at) }}
          </div>
        </template>
        <template v-slot:[`item.status_permission`]="{ item }">
          <div
            :style="
              `font-size:14px; padding: 0; display: flex; flex-direction: row; color:${statusColor(
                item.status_permission
              )};`
            "
          >
            {{ requestStatus(item.status_permission) }}
          </div>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>
<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import buildType from '../../../../services/buildType'
export default {
  props: ['result'],
  data() {
    return {
      hrsApi: buildType.apiURL('hrs'),
      build: process.env.VUE_APP_BUILD_TYPE,
      loading: true,
      paramAPI: {
        page: 1,
        keyword: '',
        offset: 0,
        limit: 10,
        status: '',
        sortBy: 'name',
        sortType: 'asc',
        company_id: 0,
        department_id: '',
        itemsPerPage: 10,
        date: ''
      },
      totalData: 0,

      options: {
        page: 1,
        itemsPerPage: 10,
        sortDesc: []
      },
      headers: [
        {
          text: 'No. Pengajuan',
          value: 'request_no',
          align: 'left',
          sortable: false
        },
        {
          text: 'Nama',
          value: 'employee',
          align: 'left',
          sortable: false
        },
        {
          text: 'Perusahaan',
          value: 'company',
          align: 'left',
          sortable: false
        },
        {
          text: 'Department/ Section',
          value: 'department',
          align: 'left',
          sortable: false
        },
        {
          text: 'Jenis Izin',
          value: 'permission_type',
          align: 'left',
          sortable: false
        },
        {
          text: 'Tgl. Izin',
          value: 'start_date',
          align: 'left',
          sortable: false
        },
        {
          text: 'Tgl. Selesai',
          value: 'end_date',
          align: 'left',
          sortable: false
        },
        {
          text: 'Jml. Hari',
          value: 'total_permission_date',
          align: 'left',
          sortable: false
        },
        // {
        //   text: 'Dibuat Pada',
        //   value: 'created_at',
        //   align: 'left',
        //   sortable: false
        // },
        // {
        //   text: 'Dibuat Oleh',
        //   value: 'created_by_name',
        //   align: 'left',
        //   sortable: false
        // },
        {
          text: 'Status',
          value: 'status_permission',
          align: 'left',
          sortable: false
        }
      ],
      response: []
    }
  },

  async mounted() {
    // this.paramAPI.company_id = this.getUserProfile.employee.company_id
    await this.getDataFromApi()
  },
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  methods: {
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },

    searchEnter() {
      this.getDataFromApi()
    },
    async getDataFromApi() {
      this.loading = true
      await this.initDataTable().then(data => {
        this.response = data.data
        this.totalData = data.total_record
        this.loading = false
      })
    },
    async initDataTable() {
      let mode = '&mode=1'
      if (
        this.getUserProfile.level.find(({ id }) => id === '38') !== undefined
      ) {
        if (this.result.nik !== this.getUserProfile.employee.nik) {
          mode = '&mode=2'
        }
      }
      if (
        this.getUserProfile.level.find(({ id }) => id === '39') !== undefined
      ) {
        mode = '&mode=3'
      }

      return await new Promise(resolve => {
        axios
          .get(
            `${this.hrsApi}employee/permission/list?keyword=${
              this.paramAPI.keyword
            }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
              this.paramAPI.limit
            }&employee_id=${this.$route.params.id}${mode}`
          )
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            console.log(err)
          })
      })
    },
    rowClick(pItem) {
      setTimeout(() => {
        this.$router.push(`/hr/permission/detail/${pItem.id}`)
      }, 100)
    },
    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      await this.getDataFromApi()
    },
    requestStatus(val) {
      switch (val) {
        case -2:
          return 'CANCELED'
        case -1:
          return 'REJECTED'
        case 0:
          return 'DRAFT'
        case 1:
          return 'WAITING APPROVAL'
        case 2:
          return 'APPROVED'
        default:
          break
      }
    },
    statusColor(val) {
      switch (val) {
        case -2:
          return 'red'
        case -1:
          return 'red'
        case 0:
          return 'blue'
        case 1:
          return 'orange'
        case 2:
          return 'green'
        default:
          break
      }
    },
    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleDateString('id')
        return local
      }
    },
    exportData() {
      alert('Cooming Soon')
    }
  }
}
</script>
