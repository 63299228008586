<template>
  <v-form
    :disabled="loading"
    ref="entryForm"
    @submit.prevent
    style="position: relative;"
  >
    <v-row style="padding: 15px" no-gutters>
      <v-col cols="12" style="padding: 0 10px; text-align:left; margin:auto;">
        <div style="font-size:18px; font-weight: bold;">
          KIRIM NOTIFIKASI KE
        </div>
      </v-col>
      <v-col cols="12" style="padding: 0 10px">
        <div flat class="d-flex justify-start align-center">
          <div style="margin-right:15px; font-size:14px;">
            Email :
          </div>
          <v-switch
            v-model="getUserProfile.notification_via_email"
            inset
            color="success"
          ></v-switch>
        </div>
      </v-col>

      <v-col cols="12" style="padding: 0 10px">
        <div flat class="d-flex justify-start align-center">
          <div style="margin-right:15px; font-size:14px;">
            HRIS :
          </div>
          <v-switch
            v-model="getUserProfile.notification_via_fcm"
            inset
            color="success"
          ></v-switch>
        </div>
      </v-col>
      <v-col cols="12" style="padding: 0 10px">
        <div flat class="d-flex justify-start align-center">
          <div style="margin-right:15px; font-size:14px;">
            WhatsApp :
          </div>
          <v-switch
            v-model="getUserProfile.notification_via_wa"
            inset
            color="success"
            disabled
          ></v-switch>
        </div>
      </v-col>
      <v-col cols="12" style="padding: 0 10px">
        <div flat class="d-flex justify-start align-center">
          <div style="margin-right:15px; font-size:14px;">
            Telegram :
          </div>
          <v-switch
            v-model="getUserProfile.notification_via_telegram"
            inset
            color="success"
            disabled
          ></v-switch>
        </div>
      </v-col>
    </v-row>
    <div
      v-if="loading"
      class="d-flex justify-center align-center"
      style="position:absolute; top:0; left:0; bottom:0; right:0; background:rgba(0,0,0,0.1);"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        size="50"
      ></v-progress-circular>
    </div>
  </v-form>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapMutations } from 'vuex'
import buildType from '../../../../services/buildType'
export default {
  data() {
    return {
      dialog: false,
      oAuth: buildType.apiURL('oauth'),
      build: process.env.VUE_APP_BUILD_TYPE,
      loading: false
    }
  },

  async mounted() {},
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  watch: {
    'getUserProfile.notification_via_email'() {
      this.notificationSetting(
        'email',
        this.getUserProfile.notification_via_email
      )
    },
    'getUserProfile.notification_via_fcm'() {
      this.notificationSetting('fcm', this.getUserProfile.notification_via_fcm)
    },
    'getUserProfile.notification_via_wa'() {
      this.notificationSetting('wa', this.getUserProfile.notification_via_wa)
    },
    'getUserProfile.notification_via_telegram'() {
      this.notificationSetting(
        'telegram',
        this.getUserProfile.notification_via_telegram
      )
    }
  },
  methods: {
    ...mapMutations(['setLoginUserData']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    notificationSetting(type, flag) {
      const body = {
        type_notification: type,
        flag: flag
      }
      this.setLoginUserData(this.getUserProfile)
      this.save(body)
    },
    async save(form) {
      this.loading = true
      await axios
        .post(`${this.oAuth}user/notification_setting`, form)
        .then(async res => {
          console.log(res)
          setTimeout(() => {
            this.loading = false

            if (res.data.status_code !== '-99') {
              this.setLoginUserData(this.getUserProfile)
            } else {
              if (res.data.status_msg === 'Parameter value has problem') {
                this.showMsgDialog(
                  'error',
                  'Invalid input, Parameter value has problem',
                  false
                )
              } else {
                this.showMsgDialog('error', res.data.status_msg, false)
              }
            }
          }, 1000)
        })
        .catch(err => {
          setTimeout(() => {
            this.loading = false
            this.showMsgDialog(
              'error',
              err
                ? 'Something went wrong, Please contact an admin!'
                : 'Something went wrong, Please contact an admin!',
              false
            )
          }, 1000)
        })
    }
  }
}
</script>
