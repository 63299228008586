var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"padding":"0","margin":"auto","border-radius":"5px"}},[_c('v-card',{attrs:{"elevation":"0"}},[(_vm.result !== null)?_c('v-toolbar',{attrs:{"color":"transparent","light":"","flat":"","prominent":""},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('v-tabs',{attrs:{"align-with-title":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":"cyan"}}),_vm._l((_vm.items),function(item,index){return _c('v-tab',{key:index,staticStyle:{"border":"1px solid #e1e1e1","text-transform":"capitalize","color":"black"}},[_vm._v(" "+_vm._s(item.title)+" ")])})],2)]},proxy:true}],null,false,3315115941)},[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-avatar',{attrs:{"size":"60"}},[_c('v-img',{attrs:{"src":_vm.result.photo,"lazy-src":"https://cdn-icons-png.flaticon.com/512/61/61173.png"}})],1),_c('v-list-item-content',[_c('v-toolbar-title',[_c('v-list-item-title',{staticStyle:{"font-weight":"bold","font-size":"18px"}},[_vm._v(" "+_vm._s(_vm.result.name)+" "),(!_vm.isEdit)?_c('v-tooltip',{attrs:{"bottom":"","color":"grey"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"position":"relative","top":"-3px"},attrs:{"x-small":"","text":"","icon":"","color":"grey darken-2"},on:{"click":function($event){return _vm.detail()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-refresh")])],1)]}}],null,false,2074794247)},[_c('span',[_vm._v("refresh page")])]):_vm._e()],1),_c('v-list-item-subtitle',[_vm._v(" ("+_vm._s(_vm.result.department !== null ? _vm.result.department.name : '')+") - "+_vm._s(_vm.result.level !== null ? _vm.result.level.name : '')+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.result.nik)+" ")])],1)],1)],1),_c('v-spacer'),_c('v-btn',{staticClass:"red--text font-weight-bold",staticStyle:{"font-size":"12px"},attrs:{"type":"button","rounded":"","elevation":"0","color":"transparent"},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-left")]),_vm._v(" Kembali ")],1)],1):_vm._e(),_c('v-tabs-items',{staticClass:"v-tab-detail-employee",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item,index){return _c('v-tab-item',{key:index},[(item.tab === 1)?_c('general-information',{attrs:{"result":_vm.result,"dropdown":_vm.dropdown,"isEdit":_vm.isEdit},on:{"cancel":_vm.detail,"editForm":_vm.editForm,"changeTab":_vm.changeTab}}):_vm._e(),(item.tab === 2)?_c('contract-detail',{attrs:{"result":_vm.result,"dropdown":_vm.dropdown,"isEdit":_vm.isEdit},on:{"cancel":_vm.detail,"editForm":_vm.editForm,"changeTab":_vm.changeTab,"findDepartmentDropdown":_vm.dropdownDepartment}}):_vm._e(),(item.tab === 3)?_c('leave-management',{attrs:{"result":_vm.result},on:{"adjusmentSubmit":_vm.detail}}):_vm._e(),(item.tab === 4)?_c('permission',{attrs:{"result":_vm.result}}):_vm._e(),(item.tab === 5)?_c('attendance',{attrs:{"result":_vm.result,"dropdown":_vm.dropdown},on:{"cancel":_vm.detail}}):_vm._e(),(_vm.result !== null)?_c('div',[(
              _vm.getUserProfile.employee.email === _vm.result.email ||
                _vm.getUserProfile.level.find(function (ref) {
                            var id = ref.id;

                            return id === '39';
}) !==
                  undefined
            )?_c('div',[(item.tab === 6)?_c('user-settings',{attrs:{"result":_vm.result}}):_vm._e()],1):_vm._e()]):_vm._e()],1)}),1)],1),_c('v-form',{ref:"entryForm",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }