<template>
  <v-container style="padding: 0; margin: auto; border-radius: 5px">
    <v-card elevation="0">
      <v-toolbar
        v-if="result !== null"
        color="transparent"
        light
        flat
        prominent
      >
        <v-list-item two-line>
          <v-list-item-avatar size="60">
            <v-img
              :src="result.photo"
              lazy-src="https://cdn-icons-png.flaticon.com/512/61/61173.png"
            />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-toolbar-title>
              <v-list-item-title style="font-weight: bold; font-size: 18px">
                {{ result.name }}
                <v-tooltip v-if="!isEdit" bottom color="grey">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      x-small
                      text
                      icon
                      color="grey darken-2"
                      style="position:relative; top:-3px"
                      @click="detail()"
                    >
                      <v-icon small>mdi-refresh</v-icon>
                    </v-btn>
                  </template>
                  <span>refresh page</span>
                </v-tooltip>
              </v-list-item-title>
              <v-list-item-subtitle>
                ({{ result.department !== null ? result.department.name : '' }})
                - {{ result.level !== null ? result.level.name : '' }}
              </v-list-item-subtitle>
              <v-list-item-subtitle> {{ result.nik }} </v-list-item-subtitle>
            </v-toolbar-title>
          </v-list-item-content>
        </v-list-item>

        <v-spacer></v-spacer>

        <!-- v-if=" getUserProfile.level.find(({ id }) => id === '37') === undefined
        " -->
        <v-btn
          type="button"
          rounded
          elevation="0"
          color="transparent"
          class="red--text font-weight-bold"
          style="font-size: 12px"
          @click="close"
        >
          <v-icon small>mdi-chevron-left</v-icon>
          Kembali
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="cyan"></v-tabs-slider>

            <v-tab
              style="
                border: 1px solid #e1e1e1;
                text-transform: capitalize;
                color: black;
              "
              v-for="(item, index) in items"
              :key="index"
            >
              {{ item.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items class="v-tab-detail-employee" v-model="tab">
        <v-tab-item v-for="(item, index) in items" :key="index">
          <general-information
            v-if="item.tab === 1"
            :result="result"
            :dropdown="dropdown"
            :isEdit="isEdit"
            @cancel="detail"
            @editForm="editForm"
            @changeTab="changeTab"
          />
          <contract-detail
            v-if="item.tab === 2"
            :result="result"
            :dropdown="dropdown"
            :isEdit="isEdit"
            @cancel="detail"
            @editForm="editForm"
            @changeTab="changeTab"
            @findDepartmentDropdown="dropdownDepartment"
          />
          <leave-management
            v-if="item.tab === 3"
            :result="result"
            @adjusmentSubmit="detail"
          />
          <permission v-if="item.tab === 4" :result="result" />
          <attendance
            v-if="item.tab === 5"
            :result="result"
            :dropdown="dropdown"
            @cancel="detail"
          />
          <div v-if="result !== null">
            <div
              v-if="
                getUserProfile.employee.email === result.email ||
                  getUserProfile.level.find(({ id }) => id === '39') !==
                    undefined
              "
            >
              <user-settings v-if="item.tab === 6" :result="result" />
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-form ref="entryForm" @submit.prevent="submit"> </v-form>
  </v-container>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import GeneralInformation from './components/GeneralInformation.vue'
import ContractDetail from './components/ContractDetail.vue'
import LeaveManagement from './components/LeaveManagement.vue'
import Permission from './components/Permission.vue'
import Attendance from './components/Absence.vue'
import UserSettings from './components/UserSettings.vue'
import buildType from '../../../services/buildType'
export default {
  name: 'employeedetail',
  components: {
    GeneralInformation,
    ContractDetail,
    LeaveManagement,
    Permission,
    Attendance,
    UserSettings
  },
  data() {
    return {
      hrsApi: buildType.apiURL('hrs'),
      build: process.env.VUE_APP_BUILD_TYPE,
      loading: false,
      tab: null,
      isEdit: false,
      items: [
        {
          tab: 1,
          title: 'Informasi Umum'
          // title: 'Biodata Pribadi'
        },
        {
          tab: 2,
          title: 'Detail Info'
          // title: 'Info Kepegawaian'
        },
        {
          tab: 3,
          title: 'Manajemen Cuti'
        },
        {
          tab: 4,
          title: 'Izin'
        },
        {
          tab: 5,
          title: 'Presensi'
        }
      ],
      text:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      wWidth: window.innerWidth,
      result: null,

      form: null,
      dropdown: {
        department: [],
        employee_status: [],
        employee_class: [1, 2, 3, 4, 5, 6, 7, 8],
        activation_status: [
          {
            id: 1,
            name: 'Active'
          },
          {
            id: 0,
            name: 'Inactive'
          }
        ],
        position: [],
        section: [],
        education: [
          'SD',
          'SMP',
          'SMA',
          'SMK',
          'D1',
          'D2',
          'D3',
          'D4',
          'S1',
          'S2',
          'S3'
        ],
        blood_type: ['A', 'B', 'AB', 'O', 'Rh-', 'Rh+'],
        ptkp_status: [],
        religion: [
          {
            name: 'Islam',
            id: 1
          },
          {
            name: 'Kristen',
            id: 2
          },
          {
            name: 'Hindu',
            id: 3
          },
          {
            name: 'Budha',
            id: 4
          },
          {
            name: 'Katolik',
            id: 6
          },
          {
            name: 'Konghucu',
            id: 7
          }
        ],
        attendance_status: [
          {
            id: 0,
            name: 'None'
          },
          {
            id: 1,
            name: 'IN'
          },
          {
            id: 2,
            name: 'OUT'
          }
        ]
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  async mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    await this.detail()
    this.initDropdown()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    ...mapActions(['dropdownPlant']),
    async initDropdown() {
      this.dropdownPlant()
      // this.dropdownDepartment()
      this.dropdownPosition()
      this.dropdownEmployeeStatus()
      this.dropdownPTKPStatus()
    },
    onResize() {
      this.wWidth = window.innerWidth
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    changeTab(v) {
      this.tab = v
    },
    editForm() {
      this.dropdownDepartment()
      this.isEdit = !this.isEdit
    },
    async detail() {
      this.loading = true
      this.result = null
      await axios
        .get(`${this.hrsApi}employee/detail/${this.$route.params.id}`)
        .then(res => {
          console.log(res)
          if (res.data.status_code !== '-99') {
            let company = null
            let department = null
            let section = null
            let unit = null
            if (res.data.data.department !== null) {
              department = {
                id: res.data.data.department.id,
                name: res.data.data.department.name
              }
              if (res.data.data.department.section !== null) {
                section = {
                  id: res.data.data.department.section.id,
                  name: res.data.data.department.section.name
                }
              }
              if (res.data.data.department.unit !== null) {
                unit = {
                  id: res.data.data.department.unit.id,
                  name: res.data.data.department.unit.name
                }
              }
            }

            if (res.data.data.company !== null) {
              company = {
                id: Number(res.data.data.company.plant_id),
                name: res.data.data.company.name
              }
            }

            this.result = {
              address: res.data.data.address,
              birth_place: res.data.data.birth_place,
              birth_date: res.data.data.birth_date,
              blood_type: res.data.data.blood_type,
              bpjs_no: res.data.data.bpjs_no,
              bpjs_tk_no: res.data.data.bpjs_tk_no,
              card_no: res.data.data.card_no,
              company: company,
              contract_date: res.data.data.contract_date,
              contract_month: res.data.data.contract_month,
              department: department,
              department_id: res.data.data.department_id,
              domicile_address: res.data.data.domicile_address,
              education_level: res.data.data.education_level,
              education_major: res.data.data.education_major,
              email: res.data.data.email,
              employee_status: res.data.data.employee_status,
              expire_contract_date: res.data.data.expire_contract_date,
              expire_leave_n_1: res.data.data.expire_leave_n_1,
              gender: res.data.data.gender,
              gol: res.data.data.gol,
              id: res.data.data.id,
              join_date: res.data.data.join_date,
              level: res.data.data.level,
              level_id: res.data.data.level_id,
              mariage_status: res.data.data.mariage_status,
              mobile_phone_no: res.data.data.mobile_phone_no,
              name: res.data.data.name,
              nik: res.data.data.nik,
              no_identitas: res.data.data.no_identitas,
              npwp_no: res.data.data.npwp_no,
              photo: res.data.data.photo,
              ptkp_status: res.data.data.ptkp_status,
              religion: res.data.data.religion,
              status: res.data.data.status,
              section: section,
              total_leave_advance: res.data.data.total_leave_advance,
              total_leave_n: res.data.data.total_leave_n,
              total_leave_n_1: res.data.data.total_leave_n_1,
              weekend_day: res.data.data.weekend_day,
              unit: unit,
              last_attendance_status: res.data.data.last_attendance_status,
              last_attendance_time: res.data.data.last_attendance_time,
              fingerprint_id: res.data.data.fingerprint_id,
              // employee_working_hour: res.data.data.employee_working_hour
              is_shift: res.data.data.is_shift
            }
            if (
              this.getUserProfile.employee.email === res.data.data.email ||
              this.getUserProfile.level.find(({ id }) => id === '39') !==
                undefined
            ) {
              if (this.items.find(({ tab }) => tab === 6) === undefined) {
                this.items.push({
                  tab: 6,
                  title: 'Settings'
                })
              }
            }
          } else {
            this.showMsgDialog('error', res.data.status_msg, false)
          }
          return (this.loading = false)
        })
        .catch(err => {
          console.log(err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          return (this.loading = false)
        })
    },
    close() {
      // console.log(this.prevRoute.path)
      if (this.prevRoute !== undefined) {
        this.$router.push(this.prevRoute.path)
      } else {
        this.$router.push('/')
      }
    },
    async dropdownDepartment() {
      await axios
        .get(
          `${this.hrsApi}master/universal/department/dropdown?filter=[{"company_id":${this.result.company.id}}]`
        )
        .then(res => {
          if (res.data.status_code === '00') {
            // if (this.result.department !== null) {
            //   this.dropdownSection()
            // }
            return (this.dropdown.department = res.data.data)
          }
          return (this.dropdown.department = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.department = [])
        })
    },
    // dropdownSection() {
    //   this.dropdown.section = []
    //   axios
    //     .get(
    //       `${this.hrsApi}master/universal/department/dropdown?filter=[{"parent_id":${this.result.department.id}}]`
    //     )
    //     .then(res => {
    //       console.log(res)
    //       if (res.data.status_code === '00') {
    //         return (this.dropdown.section = res.data.data)
    //       }
    //       return (this.dropdown.section = [])
    //     })
    //     .catch(err => {
    //       console.log(err)
    //       return (this.dropdown.section = [])
    //     })
    // },
    dropdownEmployeeStatus() {
      axios
        .get(`${this.hrsApi}master/universal/employeestatus/dropdown`)
        .then(res => {
          if (res.data.status_code === '00') {
            this.dropdown.status = res.data.data
            return (this.dropdown.employee_status = res.data.data)
          }
          this.dropdown.status = []
          return (this.dropdown.employee_status = [])
        })
        .catch(err => {
          console.log(err)
          this.dropdown.status = []
          return (this.dropdown.employee_status = [])
        })
    },
    dropdownPosition() {
      axios
        .get(`${this.hrsApi}master/universal/employeelevel/dropdown`)
        .then(res => {
          if (res.data.status_code === '00') {
            return (this.dropdown.position = res.data.data)
          }
          return (this.dropdown.position = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.position = [])
        })
    },
    async dropdownPTKPStatus() {
      await axios
        .get(`${this.hrsApi}master/universal/ptkpstatus/dropdown`)
        .then(res => {
          if (res.data.status_code === '00') {
            return (this.dropdown.ptkp_status = res.data.data)
          }
          return (this.dropdown.ptkp_status = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.ptkp_status = [])
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.v-tab-detail-employee {
  border-top: 1px solid #e1e1e1;
  max-height: 70vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
