<template>
  <v-row style="padding: 15px; position: relative" no-gutters>
    <v-col
      cols="12"
      style="padding: 0 10px; margin-top: 30px"
      class="d-flex justify-center"
      v-if="result.join_date !== null"
    >
      <v-card
        v-if="countJoinDate() >= 1"
        class="mx-auto;"
        style="width: 200px; margin: 0 10px"
      >
        <v-card-text style="text-align: center">
          <div style="font-weight: bold; position: relative">
            Saldo cuti tahun lalu
            <v-tooltip
              v-if="result.expire_leave_n_1 !== null"
              top
              color="primary"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="info"
                  style="cursor: pointer"
                  small
                  >mdi-information-outline
                </v-icon>
              </template>
              <span
                >Berlaku s/d
                {{
                  result.expire_leave_n_1.split('-').reverse().join('-')
                }}</span
              >
            </v-tooltip>
          </div>
          <div class="d-flex justify-center">
            <p
              class="text-h4 text--primary"
              style="margin: 0; font-weight: bolder"
            >
              {{ result.total_leave_n_1 || 0 }}
            </p>
            <p style="margin: 0; margin-top: auto">Hari</p>
          </div>
        </v-card-text>
      </v-card>
      <v-card
        v-if="countJoinDate() >= 1"
        class="mx-auto;"
        style="width: 200px; margin: 0 10px"
      >
        <v-card-text style="text-align: center">
          <div style="font-weight: bold">Saldo cuti tahun ini</div>
          <div class="d-flex justify-center">
            <p
              class="text-h4 text--primary"
              style="margin: 0; font-weight: bolder"
            >
              {{ result.total_leave_n || 0 }}
            </p>
            <p style="margin: 0; margin-top: auto">Hari</p>
          </div>
        </v-card-text>
      </v-card>
      <!-- <v-card
        v-if="countJoinDate() >= 1"
        class="mx-auto;"
        style="width:200px; margin: 0 10px;"
      >
        <v-card-text style="text-align:center;">
          <div style="font-weight:bold;">Total cuti yang diambil</div>
          <div class="d-flex justify-center">
            <p
              class="text-h4 text--primary"
              style="margin:0;font-weight:bolder;"
            >
              0
            </p>
            <p style="margin:0; margin-top:auto;">
              Hari
            </p>
          </div>
        </v-card-text>
      </v-card> -->
      <v-card
        v-if="countJoinDate() >= 1"
        class="mx-auto;"
        style="width: 200px; margin: 0 10px"
      >
        <v-card-text style="text-align: center">
          <div style="font-weight: bold">Sisa Cuti</div>
          <div class="d-flex justify-center">
            <p
              class="text-h4 text--primary"
              style="margin: 0; font-weight: bolder"
            >
              {{ result.total_leave_n + result.total_leave_n_1 }}
            </p>
            <p style="margin: 0; margin-top: auto">Hari</p>
          </div>
        </v-card-text>
      </v-card>
      <v-card
        v-if="countJoinDate() < 1"
        class="mx-auto;"
        style="width: 200px; margin: 0 10px"
      >
        <v-card-text style="text-align: center">
          <div style="font-weight: bold">
            Saldo Cuti Advance
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="info"
                  style="cursor: pointer"
                  small
                  >mdi-information-outline
                </v-icon>
              </template>
              <span
                >Karyawan baru dengan masa kerja dibawah 1 tahun hanya memiliki
                2 jatah cuti</span
              >
            </v-tooltip>
          </div>
          <div class="d-flex justify-center">
            <p
              class="text-h4 text--primary"
              style="margin: 0; font-weight: bolder"
            >
              {{ result.total_leave_advance || 0 }}
            </p>
            <p style="margin: 0; margin-top: auto">Hari</p>
          </div>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" style="padding: 0 10px; text-align: left">
      <br />
      <br />
      <div class="d-flex justify-content-between align-center">
        <v-toolbar-title class="overline">
          <p
            style="font-size: 16px; margin: 0; font-weight: bold; color: indigo"
          >
            Adjusment History
          </p>
        </v-toolbar-title>

        <div class="d-flex justify-content-between align-center">
          <div>
            <v-btn
              v-if="
                getUserProfile.level.find(({ id }) => id === '39') !== undefined
              "
              type="button"
              rounded
              elevation="0"
              color="primary"
              class="white--text font-weight-bold me-2"
              style="font-size: 12px"
              @click="balanceAdjusment"
            >
              Adjusment Saldo
            </v-btn>
            <v-dialog v-model="adjusment.dialog" width="500" persistent>
              <v-form
                :disabled="loading"
                ref="entryForm"
                @submit.prevent="adjusmentSubmit"
                style="position: relative"
              >
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    Formulir Adjusment Saldo Cuti
                  </v-card-title>
                  <div style="padding: 10px">
                    <v-col
                      md="12"
                      style="padding: 0; position: relative; top: 20px"
                    >
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            class="text-left"
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Sisa Cuti Periode Lalu
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0">
                          <v-text-field
                            dense
                            type="number"
                            outlined
                            v-model="adjusment.form.total_leave_n_1"
                            :rules="adjusment.rules.leave_n_1_rules"
                          />
                        </v-col>
                      </div>
                    </v-col>
                    <v-col
                      md="12"
                      style="padding: 0; position: relative; top: 20px"
                    >
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            class="text-left"
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Sisa Cuti Tahun Ini
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0">
                          <v-text-field
                            dense
                            type="number"
                            outlined
                            v-model="adjusment.form.total_leave_n"
                            :rules="adjusment.rules.leave_n_rules"
                          />
                        </v-col>
                      </div>
                    </v-col>
                    <v-col
                      md="12"
                      style="padding: 0; position: relative; top: 20px"
                    >
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            class="text-left"
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Sisa Cuti Advance
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0">
                          <v-text-field
                            dense
                            type="number"
                            outlined
                            v-model="adjusment.form.total_leave_advance"
                            :rules="adjusment.rules.leave_advance_rules"
                          />
                        </v-col>
                      </div>
                    </v-col>
                    <v-col
                      md="12"
                      style="padding: 0; position: relative; top: 20px"
                    >
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            class="text-left"
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Keterangan
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0">
                          <v-textarea
                            dense
                            auto-grow
                            rows="3"
                            outlined
                            v-model="adjusment.form.note"
                            :rules="adjusment.rules.leaveNoteRules"
                          />
                        </v-col>
                      </div>
                    </v-col>
                  </div>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-btn
                      type="button"
                      rounded
                      elevation="0"
                      color="error"
                      class="white--text font-weight-bold"
                      style="font-size: 12px; width: 100px"
                      @click="close"
                      :loading="loading"
                    >
                      Batal
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      type="submit"
                      rounded
                      elevation="0"
                      color="success"
                      class="white--text font-weight-bold"
                      style="font-size: 12px; width: 100px"
                      :loading="loading"
                    >
                      Simpan
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-dialog>
            <!-- <v-btn
              v-if="
                getUserProfile.level.find(({ id }) => id === '39') !== undefined
              "
              type="button"
              rounded
              elevation="0"
              color="primary"
              class="white--text font-weight-bold"
              style="font-size:12px;"
              @click="exportData"
            >
              Export Report
              <v-icon style="margin-left:5px;" small>mdi-download</v-icon>
            </v-btn> -->
          </div>
        </div>
      </div>
    </v-col>
    <v-col cols="12" style="padding: 0 10px">
      <v-data-table
        mobile-breakpoint="0"
        fixed-header
        min-height="20vh"
        :headers="adjusment.headers"
        style="cursor: pointer"
        :items="adjusment.response"
        :loading="loading"
        item-key="id"
        :options.sync="adjusment.options"
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
        }"
        :server-items-length="adjusment.totalData"
        @update:page="updatePage"
        @update:items-per-page="updateItemPerPage"
        @click:row="adjusmentRowClick"
      >
        <template v-slot:[`item.id`]="{ item }">
          <div style="width: 100px; overflow: hidden">
            <div
              style="
                font-size: 12px;
                padding: 0;
                display: flex;
                flex-direction: row;
              "
            >
              {{ item.id }}
            </div>
          </div>
        </template>
        <template v-slot:[`item.last_year`]="{ item }">
          <div style="font-size: 12px; padding: 0; text-align: center">
            {{
              item.body !== null
                ? item.body.after != null
                  ? item.body.after.total_leave_n_1
                  : '-'
                : '-'
            }}
          </div>
        </template>
        <template v-slot:[`item.this_year`]="{ item }">
          <div style="font-size: 12px; padding: 0; text-align: center">
            {{
              item.body !== null
                ? item.body.after != null
                  ? item.body.after.total_leave_n
                  : '-'
                : '-'
            }}
          </div>
        </template>
        <template v-slot:[`item.advance`]="{ item }">
          <div style="font-size: 12px; padding: 0; text-align: center">
            {{
              item.body !== null
                ? item.body.after != null
                  ? item.body.after.total_leave_advance
                  : '-'
                : '-'
            }}
          </div>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          <div
            style="
              font-size: 12px;
              padding: 0;
              display: flex;
              flex-direction: row;
            "
          >
            {{ convertDate(item.created_at) }}
          </div>
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="12" style="padding: 0 10px; text-align: left">
      <br />
      <v-divider></v-divider>
      <div class="d-flex justify-content-between align-center">
        <v-toolbar-title class="overline">
          <p
            style="font-size: 16px; margin: 0; font-weight: bold; color: indigo"
          >
            Pengajuan Cuti
          </p>
        </v-toolbar-title>
        <div>
          <router-link to="/hr/leave/submission">
            <v-btn
              type="button"
              rounded
              elevation="0"
              color="primary"
              class="white--text font-weight-bold"
              style="font-size: 12px; margin-left: 10px"
            >
              Formulir Pengajuan Cuti
            </v-btn>
          </router-link>
        </div>
      </div>
    </v-col>
    <v-col cols="12" style="padding: 0 10px">
      <div flat class="d-flex justify-content-between align-center">
        <div class="d-flex">
          <div style="width: 180px">
            <v-text-field
              label="Tgl. Cuti"
              v-model="leave.date"
              dense
              outlined
              type="date"
              name="date"
              step="1"
              style="position: relative; top: 15px; font-size: 12px"
            />
          </div>
          <div style="width: 180px; margin-left: 20px">
            <v-select
              label="Jenis Cuti"
              v-model="leave.leave_type"
              :items="[
                {
                  id: 1,
                  name: 'Cuti Tahunan'
                },
                {
                  id: 2,
                  name: 'Cuti Hamil'
                },
                {
                  id: 3,
                  name: 'Cuti Khusus'
                },
                {
                  id: 4,
                  name: 'Cuti Kompensasi'
                }
              ]"
              style="position: relative; top: 15px; font-size: 12px"
              item-text="name"
              item-value="id"
              return-id
              dense
              outlined
            ></v-select>
          </div>
          <div style="width: 180px; margin-left: 20px">
            <v-select
              label="Status"
              v-model="leave.status"
              :items="[
                {
                  id: 1,
                  name: 'Pending'
                },
                {
                  id: 2,
                  name: 'Approved'
                },
                {
                  id: 3,
                  name: 'Reject'
                }
              ]"
              style="position: relative; top: 15px; font-size: 12px"
              item-text="name"
              item-value="id"
              return-id
              dense
              outlined
            ></v-select>
          </div>
        </div>
        <div style="margin-left: 20px">
          <v-text-field
            v-model="leave.keyword"
            label="Cari disini"
            type="search"
            outlined
            dense
            append-icon="mdi-magnify"
            style="position: relative; top: 15px"
            @keyup.enter="searchEnter"
            :disabled="loading"
          ></v-text-field>
        </div>
      </div>

      <v-data-table
        mobile-breakpoint="0"
        fixed-header
        min-height="20vh"
        :headers="leave.headers"
        style="cursor: pointer"
        :items="leave.response"
        :loading="loading"
        @click:row="leaveRowClick"
        item-key="id"
        :options.sync="leave.options"
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
        }"
        :server-items-length="leave.totalData"
        @update:page="updateLeavePage"
        @update:items-per-page="updateLeaveItemPerPage"
      >
        <template v-slot:[`item.request_no`]="{ item }">
          <div
            style="
              font-size: 12px;
              padding: 0;
              display: flex;
              flex-direction: row;
            "
          >
            {{ item.request_no }}
          </div>
        </template>
        <template v-slot:[`item.employee`]="{ item }">
          <div
            style="
              font-size: 12px;
              padding: 0;
              display: flex;
              flex-direction: row;
            "
          >
            {{ item.employee.name }}
          </div>
        </template>
        <template v-slot:[`item.leave_type`]="{ item }">
          <div
            style="
              font-size: 12px;
              padding: 0;
              display: flex;
              flex-direction: row;
            "
          >
            {{ item.leave_type.name }}
          </div>
        </template>
        <template v-slot:[`item.status_leave`]="{ item }">
          <div
            :style="`font-size:14px; padding: 0; display: flex; flex-direction: row; color:${statusColor(
              item.status_leave
            )};`"
          >
            {{ requestStatus(item.status_leave) }}
          </div>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          <div
            style="
              font-size: 12px;
              padding: 0;
              display: flex;
              flex-direction: row;
            "
          >
            {{ convertDate(item.created_at) }}
          </div>
        </template>
        <template v-slot:[`item.request_leave_date`]="{ item }">
          <div
            @click.stop
            style="display: flex; justify-content: center; align-items: center"
          >
            <div class="leave-date-col">
              <div style="display: flex; flex-direction: row">
                <v-chip
                  small
                  label
                  v-for="(items, index) in item.request_leave_date"
                  :key="index"
                  style="
                    margin: 0 5px 0 5px;
                    font-size: 10px;
                    margin-bottom: 3px;
                  "
                >
                  {{ items.start_date + ' s/d ' + items.end_date }}
                </v-chip>
              </div>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>
<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import buildType from '../../../../services/buildType'
export default {
  props: ['result'],
  data() {
    return {
      logApi: buildType.apiURL('log'),
      hrsApi: buildType.apiURL('hrs'),
      build: process.env.VUE_APP_BUILD_TYPE,
      loading: true,
      adjusment: {
        dialog: false,
        page: 1,
        keyword: '',
        offset: 0,
        limit: 10,
        itemsPerPage: 10,
        totalData: 0,
        options: {
          page: 1,
          itemsPerPage: 10,
          sortDesc: []
        },
        headers: [
          {
            text: 'ID',
            value: 'id',
            align: 'left',
            sortable: true
          },
          {
            text: 'NIK',
            value: 'nik',
            align: 'left',
            sortable: true
          },
          {
            text: 'Nama',
            value: 'employee_name',
            align: 'left',
            sortable: true
          },
          {
            text: 'Saldo periode lalu',
            value: 'last_year',
            align: 'center',
            sortable: true
          },
          {
            text: 'Saldo tahun ini',
            value: 'this_year',
            align: 'center',
            sortable: true
          },
          {
            text: 'Saldo advance',
            value: 'advance',
            align: 'center',
            sortable: true
          },
          {
            text: 'Disesuaikan pada',
            value: 'created_at',
            align: 'left',
            sortable: true
          }
        ],
        response: [],
        form: {},
        rules: {
          leave_n_rules: [],
          leave_n_1_rules: [],
          leave_advance_rules: [],
          leaveNoteRules: []
        }
      },
      leave: {
        page: 1,
        keyword: '',
        offset: 0,
        limit: 10,
        itemsPerPage: 10,
        totalData: 0,
        date: '',
        leave_type: '',
        status: '',
        sortBy: 'name',
        sortType: 'asc',
        options: {
          page: 1,
          itemsPerPage: 10,
          sortDesc: []
        },
        response: [],
        headers: [
          {
            text: 'No. Request',
            value: 'request_no',
            align: 'left',
            sortable: true
          },
          {
            text: 'Nama',
            value: 'employee',
            align: 'left',
            sortable: true
          },
          {
            text: 'Jenis Cuti',
            value: 'leave_type',
            align: 'left',
            sortable: true
          },
          {
            text: 'Tgl. Cuti',
            value: 'request_leave_date',
            align: 'left',
            sortable: false
          },
          {
            text: 'Jml. Hari',
            value: 'total_requested_leave',
            align: 'left',
            sortable: true
          },
          // {
          //   text: 'Dibuat pada',
          //   value: 'created_at',
          //   align: 'left',
          //   sortable: true
          // },
          {
            text: 'Status',
            value: 'status_leave',
            align: 'left',
            sortable: true
          }
          // {
          //   text: 'Dibuat Oleh',
          //   value: 'created_by_name',
          //   align: 'left',
          //   sortable: true
          // }
        ]
      }
    }
  },

  async mounted() {
    // this.paramAPI.company_id = this.getUserProfile.employee.company_id
    this.getAdjusmentDataFromApi()
    this.getLeaveDataFromApi()
  },
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  methods: {
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },

    balanceAdjusment() {
      this.adjusment.form = {
        total_leave_n: this.result.total_leave_n,
        total_leave_n_1: this.result.total_leave_n_1,
        total_leave_advance: this.result.total_leave_advance,
        note: null
      }
      setTimeout(() => {
        this.adjusment.dialog = true
      }, 300)
    },
    close() {
      this.adjusment.form = {}
      this.$refs.entryForm.reset()
      setTimeout(() => {
        this.adjusment.dialog = false
      }, 200)
    },
    searchEnter() {
      this.getLeaveDataFromApi()
    },

    async getLeaveDataFromApi() {
      this.loading = true
      await this.leaveSubmission().then((data) => {
        this.leave.response = data.data
        this.leave.totalData = data.total_record
        this.loading = false
      })
    },
    async getAdjusmentDataFromApi() {
      this.loading = true
      await this.adjusmentLog().then((data) => {
        this.adjusment.response = data.data
        this.adjusment.totalData = data.total_record
        this.loading = false
      })
    },
    async leaveSubmission() {
      let mode = '&mode=1'
      if (
        this.getUserProfile.level.find(({ id }) => id === '1') !== undefined
      ) {
        mode = '&mode=3'
      }

      if (
        this.getUserProfile.level.find(({ id }) => id === '38') !== undefined
      ) {
        if (this.result.nik !== this.getUserProfile.employee.nik) {
          mode = '&mode=2'
        }
      }

      if (
        this.getUserProfile.level.find(({ id }) => id === '39') !== undefined
      ) {
        mode = '&mode=3'
      }

      return await new Promise((resolve) => {
        axios
          .get(
            `${this.hrsApi}employee/leave/list?keyword=${
              this.leave.keyword
            }&offset=${this.leave.offset * this.leave.limit}&limit=${
              this.leave.limit
            }&employee_id=${this.$route.params.id}${mode}`
          )
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      })
    },
    async adjusmentLog() {
      return await new Promise((resolve) => {
        axios
          .get(
            `${this.logApi}hris/adjust_leave/list?keyword=${
              this.adjusment.keyword
            }&offset=${this.adjusment.offset * this.adjusment.limit}&limit=${
              this.adjusment.limit
            }&employee_id=${this.result.id}`
          )
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      })
    },
    adjusmentRowClick(pItem) {},
    leaveRowClick(pItem) {
      setTimeout(() => {
        this.$router.push(`/hr/leave/detail/${pItem.id}`)
      }, 100)
    },
    async updatePage(p) {
      this.adjusment.page = p
      this.adjusment.offset = p - 1
      await this.getAdjusmentDataFromApi()
    },
    async updateItemPerPage(p) {
      this.adjusment.limit = p
      this.adjusment.offset = 0
      await this.getAdjusmentDataFromApi()
    },
    async updateLeavePage(p) {
      this.leave.page = p
      this.leave.offset = p - 1
      await this.getLeaveDataFromApi()
    },
    async updateLeaveItemPerPage(p) {
      this.leave.limit = p
      this.leave.offset = 0
      await this.getLeaveDataFromApi()
    },

    adjusmentSubmit() {
      const self = this
      // this.adjusment.rules.leave_n_rules = [
      //   v => !!v || 'This column is required'
      // ]
      // this.adjusment.rules.leave_n_1_rules = [
      //   v => !!v || 'This column is required'
      // ]
      // this.adjusment.rules.leave_advance_rules = [
      //   v => !!v || 'This column is required'
      // ]
      this.adjusment.rules.leaveNoteRules = [
        (v) => !!v || 'This column is required'
      ]
      setTimeout(function () {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const form = {
        id: this.$route.params.id,
        total_leave_n: this.adjusment.form.total_leave_n,
        total_leave_n_1: this.adjusment.form.total_leave_n_1,
        total_leave_advance: this.adjusment.form.total_leave_advance,
        note: this.adjusment.form.note
      }
      this.save(form)
    },
    async save(form) {
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee/adjust_leave_balance`, form)
        .then((res) => {
          this.loading = false
          this.close()
          if (res.data.status_code !== '-99') {
            this.getAdjusmentDataFromApi()

            this.$emit('adjusmentSubmit')
            return this.showMsgDialog('success', res.data.status_msg, false)
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              return this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              return this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch((err) => {
          this.$refs.entryForm.reset()
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          return (this.loading = false)
        })
    },
    statusColor(val) {
      switch (val) {
        case -2:
          return 'red'
        case -1:
          return 'red'
        case 0:
          return 'blue'
        case 1:
          return 'orange'
        case 2:
          return 'orange'
        case 3:
          return 'green'
        default:
          break
      }
    },
    requestStatus(val) {
      switch (val) {
        case -2:
          return 'CANCELED'
        case -1:
          return 'REJECTED'
        case 0:
          return 'DRAFT'
        case 1:
          return 'WAITING APPROVAL'
        case 2:
          return 'WAITING APPROVAL'
        case 3:
          return 'APPROVED'
        default:
          break
      }
    },
    countJoinDate() {
      const dateNow = new Date().getTime()
      const joinDate = new Date(this.result.join_date).getTime()
      const difference = dateNow - joinDate
      const totalDays = difference / (1000 * 3600 * 24)
      const totalYear = totalDays / 365
      return totalYear
    },
    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleDateString('id')
        return local
      }
    },
    exportData() {
      alert('Cooming Soon')
    }
  }
}
</script>
<style lang="scss">
.leave-date-col {
  display: flex;
  flex-direction: row;
  width: 260px;
  overflow: auto;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    // display: none;
    // position: absolute;
    // bottom: 15px;
    height: 3px;
  }
  &::-webkit-scrollbar-track-piece {
    box-shadow: 0 0 1px grey;
  }
  &::-webkit-scrollbar-thumb {
    background: rgb(184, 182, 182);
    border-radius: 10px;
  }
}
</style>
